<template>
  <v-card :loading="isLoading">
    <v-card-title>Banka İşlem Detayı</v-card-title>
    <v-card-text>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
      <v-form v-if="originalData.id">
        <v-row dense>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="TYA"
              :value="originalData.bank_account.cluster.name"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Hesap"
              :value="originalData.bank_account.name"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Tarih"
              :value="new Date(originalData.activity_on).toLocaleDateString()"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-money-field :value="originalData.amount" :disabled="true" />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-money-field
              :value="originalData.transaction_amount"
              label="Muhasebeleşen Tutar"
              :disabled="true"
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Muhasebeleşme Durumu"
              :value="originalData.status.name"
              readonly
            />
          </v-col>
          <v-col cols="12">
            <rs-textarea
              label="Açıklama"
              :value="originalData.description"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Sakin"
              :value="originalData.resident_id && originalData.resident.name"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Bağımsız Bölüm"
              :value="originalData.house_id && originalData.house.door_number"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Tedarikçi"
              :value="originalData.provider_id && originalData.provider.name"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field label="Fatura No" value="" readonly />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field label="Fatura Tarihi" value="" readonly />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field label="Tesisat No" value="" readonly />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Banka"
              :value="
                originalData.bank_account_id &&
                originalData.bank_account.bank.name
              "
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Dekont No"
              :value="originalData.receipt_no"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Detay No"
              v-model="originalData.reference_no"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-money-field
              label="Banka Bakiye"
              :value="originalData.balance"
              :disabled="true"
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Entegrasyon No"
              :value="originalData.integration_no"
              readonly
            />
          </v-col>
        </v-row>
      </v-form>
      <BankClosedDetailList :transactionId="id" />
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm, isPage } from "@/view/mixins";
import BankClosedDetailList from "@/view/pages/income/bank/BankClosedDetailList.vue";
export default {
  name: "BankActivityDetail",
  mixins: [hasForm, isPage],
  props: {
    /**
     * Bank Activity ID
     * @type {Number}
     */
    id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    id(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  components: {
    BankClosedDetailList,
  },
  data() {
    return {
      pageMeta: {
        title: "Banka Online İşlemler Detay",
      },
    };
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => this.load());
    }
  },
  methods: {
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("income/bank-activities", this.id)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
