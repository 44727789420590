<template>
  <v-row dense class="mt-5">
    <v-col sm="12">
      <h4 class="text-dark mb-3">Hareket Listesi</h4>
      <v-simple-table dense>
        <template v-slot:default>
          <thead v-if="list.length">
            <tr class="bg-secondary bordered">
              <th class="text-left sticky bg-secondary">Tutar</th>
              <th class="text-left sticky bg-secondary">Tarih - Saat</th>
              <th class="text-center sticky bg-secondary">Detay</th>
              <th class="text-center sticky bg-secondary">İptal</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="account in list" :key="account.id" class="bordered">
              <td class="w-50">
                <rs-table-cell-number price :value="account.amount" />
              </td>
              <td class="w-50">
                <rs-table-cell-date show-time :value="account.created_at" />
              </td>
              <td class="w-20px">
                <v-btn
                  class="mt-2 mb-2"
                  color="primary"
                  small
                  title="Detay"
                  @click.prevent="goDetail(account)"
                >
                  {{ detailText(account) }}
                  <v-icon class="ms-1" small>mdi-arrow-right</v-icon>
                </v-btn>
              </td>
              <td class="w-20px">
                <v-btn
                  class="mt-2 mb-2"
                  color="error"
                  small
                  title="İptal Et"
                  @click.prevent="cancelOperation(account.id)"
                >
                  İptal Et
                  <v-icon class="ms-1" small>mdi-redo</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr v-if="!list.length">
              <td class="text-center">
                <span class="text-muted">Hiç hareket yok</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDeleteConfirmed"
      @cancelled="$refs.confirmDelete.hide()"
    />
    <CollectingDetail ref="collectingDetailForm" />
    <TransferDetail ref="transferDetail" />
  </v-row>
</template>
<script>
import { CollectingDetail } from "@/view/pages/income/forms";
import TransferDetail from "@/view/pages/activities/TransferDetail";
import { hasDataTable } from "@/view/mixins";

export default {
  name: "BankClosedDetailList",
  props: {
    transactionId: {
      type: String,
      required: true,
    },
  },
  mixins: [hasDataTable],
  components: {
    CollectingDetail,
    TransferDetail,
  },
  data() {
    return {
      isLoading: false,
      list: [],
      id: null,
    };
  },
  methods: {
    detailText(item) {
      let text = "";
      if (item.collecting_id) {
        text = "Tahsilat";
      } else if (item.enforcement_id) {
        text = "İcra";
      } else if (item.invoice_id) {
        text = "Fatura Ödeme";
      } else if (item.invoice_payment_invoice_id) {
        text = "Fatura Ödeme";
      } else if (item.other_income_id) {
        text = "Diğer Gelir Tahsilat";
      } else if (item.transfer_id) {
        text = "Transfer";
      } else {
        text = "Elle";
      }

      return text;
    },
    goDetail(item) {
      if (item.collecting_id) {
        this.$refs.collectingDetailForm.show(item.collecting_id, true);
      } else if (item.enforcement_id) {
        this.$router.push({
          name: "enforcements.show",
          params: { id: item.enforcement_id },
        });
      } else if (item.invoice_id) {
        this.$router.push({
          name: "expenses.invoices.show",
          params: { id: item.invoice_id },
        });
      } else if (item.invoice_payment_invoice_id) {
        this.$router.push({
          name: "expenses.invoices.show",
          params: { id: item.invoice_payment_invoice_id },
        });
      } else if (item.other_income_id) {
        this.$router.push({
          name: "incomes.other.show",
          params: { id: item.other_income_id },
        });
      } else if (item.transfer_id) {
        this.$refs.transferDetail.show(item.transfer_id);
      } else {
        return;
      }
    },
    cancelOperation(id) {
      if (!id) {
        return;
      }

      this.id = id;

      this.$refs.confirmDelete.show(
        "Hareketi silmek istediğinizden emin misiniz?"
      );
    },
    handleDeleteConfirmed() {
      if (this.isLoading) return false;

      this.isLoading = true;

      this.$api
        .delete(
          `income/bank-activities/${this.transactionId}/transactions/${this.id}`
        )
        .then(() => {
          setTimeout(() => {
            this.loadList();
          }, 50);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$refs.confirmDelete.hide();
          this.isLoading = false;
        });
    },
    loadList() {
      if (!this.transactionId) {
        return;
      }

      const params = {
        per_page: 200,
        order_by: "created_at",
      };

      this.$api
        .query(`income/bank-activities/${this.transactionId}/transactions`, {
          params,
        })
        .then((response) => {
          this.list = [];

          if (
            response.data &&
            response.data.data &&
            response.data.data.length
          ) {
            this.list = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>
